﻿// Libraries
import m, { request } from 'mithril'
import { find, map, groupBy, filter } from 'lodash'

// Models
import Film from '../models/Film'

// Components
import { card } from 'bootstrap-mithril'
import formGroup from '../components/formGroup'
import saveBtn from '../components/saveBtn'
import loading from '../components/loading'
import icon from '../components/icon'
import { actionbar } from '../components/actionbar'
import notifications from '../components/notifications'
import dateFormat from 'dateformat'
import User from '../models/User'

var pageFilmEntrant = {}

pageFilmEntrant.oninit = (vnode) => {
	if (!User.films()) {
		User.getUserFilms().then(() => {
			if (
				User.films() &&
				User.films().length > 0 &&
				find(User.films(), (f) => {
					return f.id === parseInt(vnode.attrs.id)
				})
			) {
				Film.get(vnode.attrs.id)
			}
		})
	} else {
		Film.get(vnode.attrs.id)
	}
}

pageFilmEntrant.view = (vnode) => {
	return Film.current()
		? [
				m(actionbar, {
					breadcrumbs: [
						{
							text: 'Dashboard',
							href: `/`,
						},
						{
							text: 'Film: ' + Film.current().Title,
						},
					],
				}),
				m('.container-fluid', [
					m('.row.main-content', [
						m('.col-md-6.col-lg-7', [
							m('.card.mb-3', [
								m('.row.no-gutters', [
									m('.col-md-12.col-lg-4.order-2.order-lg-1', [
										Film.current().PosterUrl
											? m('img.card-img', {
													src: `${Film.current().PosterUrl}?w=600&fm=jpg`,
											  })
											: m('svg.card-img-top', { 'text-anchor': 'middle', role: 'img' }, [
													m('rect', { width: '100%', height: '100%', fill: '#868e96' }),
													m('text', { x: '50%', y: '50%', fill: '#efefef', dy: '.3em' }, 'Poster coming soon'),
											  ]),
										m('.p-3', [
											m(formGroup, {
												label: 'Screening',
												isStatic: true,
												resource: Film,
												property: 'ScreeningInfo',
											}),
											Film.current().ScreeningLink &&
												m(formGroup, {
													label: 'Screening link',
													isStatic: true,
													resource: Film,
													link: { href: Film.current().ScreeningLink, target: '_blank' },
													property: 'ScreeningLink',
													value: ['View Screener', m(icon, { iconName: 'external-link ml-2' })],
												}),
											vnode.state.screenRequest &&
												m(
													'p',
													"Your screener request has been received and will be passed to the film's distributor; please note it may take up to 5 working days for the screener to be delivered to you"
												),
											Film.current().ScreeningLinkPassword
												? m(formGroup, {
														label: 'Screening password',
														isStatic: true,
														resource: Film,
														property: 'ScreeningLinkPassword',
												  })
												: null,
										]),
									]),
									m('.col-md-12.col-lg-8.order-1.order-lg-2', [
										m('.card-body', [
											m('h3.card-title', Film.current().Title),
											m.trust(Film.current().Description),
											m('hr'),
											m('.row', [
												Film.current().RunningTime
													? m('.col-md-12', [
															m(formGroup, {
																label: 'Running time (mins)',
																isStatic: true,
																resource: Film,
																property: 'RunningTime',
															}),
													  ])
													: null,
											]),
										]),
									]),
								]),
							]),
							// Film.current().TrailerVimeoId || Film.current().TrailerYoutubeId
							// 	? m(card, {
							// 			header: 'Watch Trailer',
							// 			bodyClasses: ['p-0'],
							// 			body: [
							// 				m('.embed-responsive.embed-responsive-16by9', [
							// 					m('iframe.embed-responsive-item', {
							// 						allowfullscreen: true,
							// 						src: Film.current().TrailerVimeoId
							// 							? `https://vimeo.com/${Film.current().TrailerVimeoId}`
							// 							: `https://www.youtube.com/embed/${Film.current().TrailerYoutubeId}`,
							// 					}),
							// 				]),
							// 			],
							// 	  })
							// 	: null,
							Film.current().Entries &&
								find(Film.current().Entries, (x) => {
									return x.EntryStatusId === 2
								}) &&
								m(card, {
									header: 'Longlisted Entries',
									classes: ['card-sm', 'mb-3'],
									bodyClasses: ['p-0'],
									body: [
										m('table.table.table-sm', [
											m('thead', [m('tr', [m('th', 'Category'), m('th', 'People')])]),
											m('tbody', [
												map(
													groupBy(
														filter(Film.current().Entries, (x) => {
															return x.EntryStatusId === 2
														}),
														(a) => a.EntryId
													),
													(entry) => {
														return m('tr', [
															m('td', entry[0].VotingCategoryShortName),
															m(
																'td',
																entry[0].Person
																	? m('ul.list-unstyled', [
																			map(entry, (person) => {
																				return m('li.list-item', [
																					m(icon, { iconName: 'user mr-1' }),
																					person.Person,
																					person.Character
																						? ` (${person.Character})`
																						: person.Role
																						? ` (${person.Role})`
																						: null,
																				])
																			}),
																			entry[0].Attachments.length
																				? map(entry[0].Attachments, (file) => {
																						return m('li.list-item', [
																							m(
																								'a',
																								{
																									href: `/download/entry-file?id=${file.id}`,
																									target: '_blank',
																								},
																								[m(icon, { iconName: 'file mr-1' }), file.Name]
																							),
																						])
																				  })
																				: null,
																			entry[0].Statement
																				? m(
																						'button.btn.btn-sm.btn-secondary',
																						{
																							'data-toggle': 'modal',
																							'data-target': `#statement-${entry[0].EntryId}`,
																						},
																						'Statement'
																				  )
																				: null,
																			entry[0].Statement
																				? m(
																						'.modal.fade',
																						{
																							oncreate: (vnode) => {
																								$(vnode.dom).modal({
																									focus: false,
																									show: false,
																								})
																							},
																							onremove: (vnode) => {
																								$(vnode.dom).modal('dispose')
																							},
																							id: `statement-${entry[0].EntryId}`,
																							role: 'dialog',
																							tabindex: -1,
																							'data-container': 'body',
																						},
																						[
																							m('.modal-dialog.modal-xl', [
																								m('.modal-content', [
																									m('.modal-header', [
																										m('h5', entry[0].VotingCategoryShortName + ' Statement'),
																									]),
																									m('.modal-body', [
																										m.trust(
																											entry[0].Statement.replace(
																												/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
																												'$1<br>$2'
																											)
																										),
																									]),
																								]),
																							]),
																						]
																				  )
																				: null,
																	  ])
																	: null
															),
														])
													}
												),
											]),
										]),
									],
								}),
							Film.current().Entries &&
								!find(Film.current().Entries, (x) => {
									return x.EntryStatusId === 2
								}) &&
								m(card, {
									header: 'Entries',
									classes: ['card-sm'],
									bodyClasses: ['p-0'],
									body: [
										m('table.table.table-sm', [
											m('thead', [m('tr', [m('th', 'Category'), m('th', 'People')])]),
											m('tbody', [
												map(
													groupBy(Film.current().Entries, (a) => a.EntryId),
													(entry) => {
														// return entry[0].EntryStatusId === 1 && m('tr', [
														return m('tr', [
															m('td', entry[0].VotingCategoryShortName),
															m(
																'td',
																entry[0].Person
																	? m('ul.list-unstyled', [
																			map(entry, (person) => {
																				return m('li.list-item', [
																					m(icon, { iconName: 'user mr-1' }),
																					person.Person,
																					person.Character
																						? ` (${person.Character})`
																						: person.Role
																						? ` (${person.Role})`
																						: null,
																				])
																			}),
																			entry[0].Attachments.length
																				? map(entry[0].Attachments, (file) => {
																						return m('li.list-item', [
																							m(
																								'a',
																								{
																									href: `/download/entry-file?id=${file.id}`,
																									target: '_blank',
																								},
																								[m(icon, { iconName: 'file mr-1' }), file.Name]
																							),
																						])
																				  })
																				: null,
																			entry[0].Statement
																				? m(
																						'button.btn.btn-sm.btn-secondary',
																						{
																							'data-toggle': 'modal',
																							'data-target': `#statement-${entry[0].EntryId}`,
																						},
																						'Statement'
																				  )
																				: null,
																			entry[0].Statement
																				? m(
																						'.modal.fade',
																						{
																							oncreate: (vnode) => {
																								$(vnode.dom).modal({
																									focus: false,
																									show: false,
																								})
																							},
																							onremove: (vnode) => {
																								$(vnode.dom).modal('dispose')
																							},
																							id: `statement-${entry[0].EntryId}`,
																							role: 'dialog',
																							tabindex: -1,
																							'data-container': 'body',
																						},
																						[
																							m('.modal-dialog.modal-xl', [
																								m('.modal-content', [
																									m('.modal-header', [
																										m('h5', entry[0].VotingCategoryShortName + ' Statement'),
																									]),
																									m('.modal-body', [
																										m.trust(
																											entry[0].Statement.replace(
																												/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
																												'$1<br>$2'
																											)
																										),
																									]),
																								]),
																							]),
																						]
																				  )
																				: null,
																	  ])
																	: null
															),
														])
													}
												),
											]),
										]),
									],
								}),
						]),
						m('.col-md-6.col-lg-5', [
							Film.current().Entries &&
								find(Film.current().Entries, (x) => {
									return x.EntryStatusId === 2
								}) &&
								m(card, {
									header: m('.d-flex.justify-content-between', [m('.c-title', 'Your longlist feedback')]),
									classes: ['mb-3'],
									headerClasses: Film.current().isLonglistedSignedOff ? ['bg-success', 'text-white'] : null,
									body: [
										m('ul', [
											m(
												'li',
												m.trust(
													'Please thoroughly check category listings and spellings and email us ASAP if any changes need to be made to spellings <strong>or the order of names</strong>. Publicly announced longlists will be released from w/c 16 October. <strong>All other longlists remain confidential</strong>.'
												)
											),
											m('li', [
												'All entries in the below categories should have the following supporting materials:',
												m('ul', [
													m('li', 'Statements - Casting, Effects (+ reel which is optional but welcome)'),
													m(
														'li',
														'Completed Questionnaires - Breakthrough Producer, Maverick, Music Supervision, Original Music'
													),
													m('li', 'All music entries should also have a Music Cue Sheet'),
													m('li', 'Screenplay samples - Debut Screenwriter, Best Screenplay'),
												]),
											]),
											m('li', 'Please email any missing materials to us ASAP.'),
										]),
										m('p.font-weight-bold', 'Please mark the box below to confirm that you have checked your entry.'),
										m('.s-ns-c', [
											m('.custom-control.custom-switch.custom-control-inline.custom-control-input-success', [
												m('input.custom-control-input', {
													type: 'checkbox',
													id: 'i-longlist',
													name: 's-ns-d',
													checked: Film.current().isLonglistedSignedOff,
													onchange: (e) => {
														Film.current().isLonglistedSignedOff = (e.currentTarget || e.target).checked
														Film.setEntrantLongistedConfirm(Film.current().id)
													},
												}),
												m(
													'label.custom-control-label',
													{
														for: 'i-longlist',
													},
													'Confirmed'
												),
											]),
										]),
										Film.current().isLonglistedSignedOff &&
											Film.current().LonglistedSignedOffDate &&
											m('p.small.text-black-50.mt-2', [
												`Entry confirmed ${dateFormat(Film.current().LonglistedSignedOffDate, 'dd/mm/yyyy HH:MM')}`,
											]),
									],
								}),
							Film.current()
								? m(card, {
										header: m('.d-flex.justify-content-between', [m('.c-title', 'Your feedback')]),
										headerClasses: Film.current().isSignedOff ? ['bg-success', 'text-white'] : null,
										body: [
											m('ul', [
												m('li', 'Please thoroughly check category listings and spellings.'),
												m(
													'li',
													'All entries in the following categories should have supporting statements: Maverick Award, Best Casting and Best Effects.'
												),
												m('li', 'All Screenplay and Debut Screenwriter entries should also have a screenplay sample.'),
												m('li', 'All Music Supervision and Original Music entries should have a music cue sheet.'),
												m(
													'li',
													'Any missing materials and missing online screeners should be emailed to entry@bifa.film by Friday 15 September'
												),
												m(
													'li',
													'To make amendments to your entry, email entry@bifa.film by Wednesday 13 September at 6pm.'
												),
											]),
											m(
												'p.font-weight-bold',
												'Please mark the box below to confirm that you have checked your entry (deadline Wednesday 13 September at 6pm).'
											),
											m('.s-ns-c', [
												m('.custom-control.custom-switch.custom-control-inline.custom-control-input-success', [
													m('input.custom-control-input', {
														type: 'checkbox',
														id: 'i-seen',
														name: 's-ns-c',
														checked: Film.current().isSignedOff,
														disabled:
															Film.current().Entries &&
															find(Film.current().Entries, (x) => {
																return x.EntryStatusId === 2
															})
																? true
																: null,
														onchange: (e) => {
															Film.current().isSignedOff = (e.currentTarget || e.target).checked
															Film.setEntrantConfirm(Film.current().id)
														},
													}),
													m(
														'label.custom-control-label',
														{
															for: 'i-seen',
														},
														'Confirmed'
													),
												]),
											]),
											Film.current().isSignedOff &&
												Film.current().SignedOffDate &&
												m('p.small.text-black-50.mt-2', [
													`Entry confirmed ${dateFormat(Film.current().SignedOffDate, 'dd/mm/yyyy HH:MM')}`,
												]),
										],
								  })
								: m(loading),
						]),
					]),
				]),
		  ]
		: m(loading)
}

export default pageFilmEntrant
